<template>
  <div class="trading-ui-header">
    <div class="trading-ui-header__navigator">
      <IconButton
        class="trading-ui-header__navigator-return"
        icon="arrow-left"
        size="small"
        @click="goBack"
      />
      <div
        ref="navigatorSearchRoot"
        class="trading-ui-header__navigator-search"
      >
        <div
          v-if="eventName"
          class="trading-ui-header__navigator-title"
          :class="{ 'trading-ui-header__navigator-title--active': eventSearchActive }"
          @click="eventSearchToggle"
        >
          <h6>
            {{ eventName }}
          </h6>
          <Icon
            class="trading-ui-header__navigator-icon"
            name="chevron-down"
          />
        </div>
        <TradingUIHeaderSearch
          v-if="eventSearchActive"
          ref="searchEventsRootRef"
          @eventSelected="eventSelected"
        />
      </div>
      <div class="trading-ui-header__navigator-info">
        <div class="trading-ui-header__navigator-breadcrumbs">
          <span
            class="trading-ui-header__navigator-breadcrumbs-item"
            @click="navigate({ sportId })"
          >
            {{ sportName }}
          </span>
          <Icon
            name="chevron-right"
          />
          <span
            class="trading-ui-header__navigator-breadcrumbs-item"
            @click="navigate({ sportId, regionId, competitionId })"
          >
            {{ competitionName }}
          </span>
        </div>
        <div
          class="trading-ui-header__navigator-separator"
        />
        <div class="trading-ui-header__navigator-text">
          <Icon
            name="calendar"
          />
          {{ date }}
        </div>
        <div class="trading-ui-header__navigator-text">
          <Icon
            name="clock"
          />
          {{ time }}
        </div>
      </div>
    </div>
    <div class="right-side">
      <div
        class="risk-flags"
        v-if="riskFlags"
      >
        <Icon :name="'alert-cycle'" />
        <div class="risk-flags-label">
          {{ riskFlags }}
        </div>
      </div>

      <div
        class="feed-selection-dropdown-wrapper"
        ref="feedDropdownRef"
      >
        <div
          class="selected-feed-value"
          v-if="tradingEventSelectedFeed"
          @click="() => { feedDropdownToggled = !feedDropdownToggled }"
        >
          <span>Primary feed:</span>
          <span class="feed-span">
            {{ tradingEventSelectedFeed }}
          </span>
          <Icon
            class="filter-icon"
            :name="'chevron-selector'"
          />
        </div>
        <div
          class="selected-feed-value"
          v-else
        >
          <span class="no-feed-span">
            Event state not available
          </span>
        </div>
        <div
          class="feed-selection-dropdown"
          v-if="feedDropdownToggled"
        >
          <div class="feed-selection-dropdown-message">
            Select which one you want to preview and which one you want to apply:
          </div>
          <div
            class="feed-selection-dropdown-option"
            v-for="(feed, index) in tradingEventFeeds"
            :key="index"
            @click.stop="selectPrimaryFeed(feed)"
          >
            <div class="feed-selection-dropdown-option-item">
              <RadioInput
                :checked="feed === tradingEventSelectedFeed"
              />
              {{ feed }}
            </div>
            <Button
              variant="primary"
              :disabled="feed === appliedFeedSource"
              small
              @click="applyFeedSource(feed)"
            >
              {{ feed === appliedFeedSource ? 'Applied' : 'Apply' }}
            </Button>
          </div>
        </div>
      </div>
      <Button
        v-if="superAdminEnabled"
        @click="refreshPrices"
        :loading="refreshPricesLoading"
      >
        Refresh markets
      </Button>
      <Button
        :icon="isEventSuspended ? 'play-circle' : 'pause-circle'"
        :variant="isEventSuspended ? 'secondary' : 'danger'"
        class="suspend-event-btn"
        :class="{ 'event-suspended': isEventSuspended }"
        @click="toggleEventSuspend(!isEventSuspended)"
        :loading="isEventSuspendLoading"
      >
        {{ isEventSuspended ? 'Unsuspend' : 'Suspend event' }}
      </Button>
      <Button
        class="copy-id-btn"
        icon="copy"
        @click="copyEventId"
        :disabled="!data?.eventId"
      >
        Copy event ID
      </Button>
      <Dropdown
        icon="dots-vertical"
        hide-chevron
        right
      >
        <DropdownItem
          :disabled="toggleAllMarketsDisplayProcessing || (!isEventSuspended && isEveryMarketVisible)"
          clickable
          @click="toggleAllMarketsDisplay"
        >
          <Icon
            :name="toggleAllMarketsDisplayIcon"
          />
          <span class="all-markets-display-label">
            {{ toggleAllMarketsDisplayLabel }}
          </span>
        </DropdownItem>
        <DropdownItem
          clickable
          @click="openAuditLog"
          class="audit-log-dropdown-item"
          v-if="auditLogEnabled"
        >
          <Icon
            :name="'external'"
          />
          <span class="all-markets-display-label">
            Audit log
          </span>
        </DropdownItem>
      </Dropdown>
    </div>
    <div
      v-if="isPrimaryFeedChangeWarningVisible"
      class="primary-feed-change-warning"
    >
      <div class="primary-feed-change-warning__modal">
        <div class="primary-feed-change-warning__header">
          Changing primary feed for event
        </div>
        <div class="primary-feed-change-warning__content">
          Changing primary feed could impact the current state of the event. Are you sure you want to proceed?
        </div>
        <div class="primary-feed-change-warning__footer">
          <Button
            v-if="!isChangingPrimaryFeed"
            variant="tertiary"
            @click="dismissPrimaryFeedChange"
          >
            Dismiss
          </Button>
          <Button
            variant="primary"
            :disabled="isChangingPrimaryFeed"
            @click="confirmPrimaryFeedChange"
          >
            {{ isChangingPrimaryFeed ? 'Applying...' : 'Apply primary feed' }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import {
  capitalize,
  split,
  pickBy,
  identity,
  join,
  reverse,
  filter,
} from 'lodash';
import { onClickOutside } from '@vueuse/core';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import IconButton from '@/components/common/IconButton';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import RadioInput from '@/components/common/RadioInput';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import { displayAllEventMarkets, undisplayAllEventMarkets, changeEventPrimaryFeeds } from '@/services/api';
import eventsHelper from '@/services/helpers/event';
import TradingUIHeaderSearch from './TradingUIHeaderSearch';

export default {
  components: {
    Icon,
    Button,
    IconButton,
    Dropdown,
    DropdownItem,
    RadioInput,
    TradingUIHeaderSearch,
  },
  props: {
    riskFlags: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const data = computed(() => store.getters.tradingEvent || {});
    const appliedFeedSource = computed(() => eventsHelper.getEventDetailsSource(data.value));
    const isEventSuspended = computed(() => data.value.isSuspended);
    const isEventSuspendLoading = computed(() => store.getters.tradingEventSuspendLoading);
    const eventSearchActive = computed(() => store.getters.tradingEventSearchActive);
    const { isSuperAdmin, SUPER_ADMIN_CLIENT } = getSuperAdminData();
    const selectedOperator = computed(() => route.query.client);
    const superAdminEnabled = computed(() => isSuperAdmin && selectedOperator.value === SUPER_ADMIN_CLIENT);
    const auditLogEnabled = computed(() => isSuperAdmin);
    const eventName = computed(() => {
      const competitors = split(data.value.eventName, ' v ');
      if (data.value.isUsaView) return join(reverse(competitors), ' @ ');
      return join(competitors, ' vs ');
    });
    const date = computed(() => {
      if (!data.value.startsAt) return 'N/A';
      return format(zonedTimeToUtc(data.value.startsAt, 'UTC'), 'MMM d, yyyy');
    });
    const time = computed(() => {
      if (!data.value.startsAt) return 'N/A';
      return format(zonedTimeToUtc(data.value.startsAt, 'UTC'), 'hh:mm a');
    });
    const sportId = computed(() => data.value?.sport?.sportId);
    const regionId = computed(() => data.value?.region?.regionId);
    const competitionId = computed(() => data.value?.competition?.competitionId);
    const sportName = computed(() => data.value?.sport?.sportName ?? 'N/A');
    const competitionName = computed(() => data.value?.competition?.competitionName ?? 'N/A');
    const eventSearchToggle = () => {
      store.dispatch('setIsTradingEventSearchActive', !eventSearchActive.value);
    };

    const feedDropdownToggled = ref(false);
    const feedDropdownRef = ref(null);
    const tradingEventFeeds = computed(() => store.getters.tradingEventFeeds);
    const tradingEventSelectedFeed = computed(() => store.getters.tradingEventSelectedFeed);

    const selectPrimaryFeed = (feed) => {
      if (feed !== tradingEventSelectedFeed.value) store.dispatch('setTradingEventSelectedFeed', feed);
      feedDropdownToggled.value = false;
    };

    const navigatorSearchRoot = ref(null);
    onClickOutside(navigatorSearchRoot, () => {
      store.dispatch('setIsTradingEventSearchActive', false);
    });

    const eventSelected = (eventId) => {
      router.push({
        name: 'trading-ui',
        params: {
          eventId,
        },
      });
    };

    const navigate = (options) => {
      if (!options.sportId && !options.regionId && !options.competitionId) return;

      router.push({
        name: 'events',
        query: pickBy({
          sport: options.sportId,
          region: options.regionId,
          competition: options.competitionId,
        }, identity),
      });
    };

    const goBack = () => router.back();

    const toggleEventSuspend = (isSuspended) => {
      store.dispatch('toggleTradingUIEventSuspend', { eventId: data.value.eventId, isSuspended });
    };

    const refreshPricesLoading = ref(false);

    const refreshPrices = () => {
      if (!getSuperAdminData()?.isSuperAdmin) return;
      refreshPricesLoading.value = true;
      store.dispatch('refreshPrices', data.value.eventId)
        .then(() => {
          refreshPricesLoading.value = false;
        })
        .catch(() => {
          console.log('Reset markets failed');
          refreshPricesLoading.value = false;
        });
    };

    const isEveryMarketVisible = computed(() => {
      const everyVisibleMarket = filter(store.getters.tradingEventMarkets, (market) => market.displayStatus === 'ON');
      return !!everyVisibleMarket.length;
    });
    const toggleAllMarketsDisplayProcessing = ref(false);
    const toggleAllMarketsDisplayIcon = computed(() => (isEveryMarketVisible.value ? 'eye-off' : 'eye'));
    const toggleAllMarketsDisplayLabel = computed(() => (isEveryMarketVisible.value ? 'Hide all markets' : 'Show all markets'));
    const toggleAllMarketsDisplay = () => {
      if (toggleAllMarketsDisplayProcessing.value || (!isEventSuspended.value && isEveryMarketVisible.value)) return;
      toggleAllMarketsDisplayProcessing.value = true;
      const apiRequest = isEveryMarketVisible.value
        ? undisplayAllEventMarkets
        : displayAllEventMarkets;
      apiRequest({
        id: data.value.eventId,
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        toggleAllMarketsDisplayProcessing.value = false;
      });
    };

    const copyEventId = () => {
      if (!navigator?.clipboard) return;
      navigator.clipboard.writeText(data.value.eventId);
    };

    const newPrimaryFeed = ref('');
    const isChangingPrimaryFeed = ref(false);
    const isPrimaryFeedChangeWarningVisible = computed(() => !!newPrimaryFeed.value?.length);
    const applyFeedSource = (feed) => {
      feedDropdownToggled.value = false;
      newPrimaryFeed.value = feed;
    };
    const dismissPrimaryFeedChange = () => {
      newPrimaryFeed.value = '';
    };
    const confirmPrimaryFeedChange = () => {
      isChangingPrimaryFeed.value = true;
      changeEventPrimaryFeeds({
        id: data.value.eventId,
        primaryFeeds: [newPrimaryFeed.value],
      })
        .then(() => {
          store.dispatch('reloadTradingEvent');
          store.dispatch('addNotification', {
            message: `${capitalize(newPrimaryFeed.value)} successfully applied as primary feed for this event!`,
            type: 'success',
            duration: 5000,
          });
        })
        .catch((error) => {
          console.error(error);
          store.dispatch('addNotification', {
            message: `Couldn't set ${capitalize(newPrimaryFeed.value)} as primary feed for this event. Please try again later!`,
            type: 'error',
            duration: 5000,
          });
        })
        .finally(() => {
          newPrimaryFeed.value = '';
          isChangingPrimaryFeed.value = false;
        });
    };

    const openAuditLog = () => {
      const routeOptions = {
        name: 'audit-log',
        params: {
          eventId: data.value.eventId,
        },
      };

      router.push(routeOptions);
    };

    onClickOutside(feedDropdownRef, () => {
      feedDropdownToggled.value = false;
    });

    return {
      data,
      appliedFeedSource,
      isEventSuspended,
      isEventSuspendLoading,
      eventName,
      date,
      time,
      sportId,
      regionId,
      competitionId,
      sportName,
      competitionName,
      eventSearchActive,
      navigatorSearchRoot,
      eventSearchToggle,
      eventSelected,
      navigate,
      goBack,
      toggleEventSuspend,
      superAdminEnabled,
      refreshPrices,
      refreshPricesLoading,
      isEveryMarketVisible,
      toggleAllMarketsDisplayProcessing,
      toggleAllMarketsDisplayIcon,
      toggleAllMarketsDisplayLabel,
      toggleAllMarketsDisplay,
      copyEventId,
      tradingEventFeeds,
      tradingEventSelectedFeed,
      feedDropdownToggled,
      feedDropdownRef,
      selectPrimaryFeed,
      isChangingPrimaryFeed,
      isPrimaryFeedChangeWarningVisible,
      applyFeedSource,
      dismissPrimaryFeedChange,
      confirmPrimaryFeedChange,
      openAuditLog,
      auditLogEnabled,
    };
  },
};
</script>

<style lang="scss">
.trading-ui-header {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 43px;

  .copy-id-btn {
    .icon {
      svg {
        stroke: #000;
      }
    }
  }

  .event-meta__date-time {
    display: flex;
  }

  .event-meta__breadcrumbs {
    display: flex;
  }

  .go-back-icon {
    transform: rotate(180deg);
  }

  .left-side {
    display: flex;
    flex-direction: column;
    position: relative;

    .icon {
      width: 16px;
      height: 16px;
    }
    .event-info {
      display: flex;
      align-items: center;

      .event-name {
        display: flex;
        cursor: pointer;
        padding: 0 4px;
        border-radius: 4px;
        transition: background-color .3s;

        &:hover {
          background-color: $gray400;
        }

        .open-event-list-icon {
          margin-top: 5px;
          margin-left: 10px;
          cursor: pointer;
          stroke: $black;
          transition: transform .3s;
        }

        &.active {
          .open-event-list-icon {
            transform: rotate(180deg);
          }
        }
      }
      .date-time-info {
        font-size: 14px;
        line-height: 17px;
        color: #a9a9a9;
      }
    }
    .trading-ui-event-search {
      position: absolute;
      top: 30px;
      left: 30px;
      z-index: 200;
    }
  }
  .right-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    height: 100%;
    .suspend-event-btn {
      .icon svg path {
        fill: $black;
      }
      &:not(.event-suspended) {
        .icon svg path {
          fill: $white;
        }
      }
      &.button--loading {
        .icon svg path {
          fill: unset;
          stroke: $black;
        }
      }
    }
    .divider {
      height: 33px;
      width: 1px;
      background-color: $gray400;
    }
    .publish-btn {
      .icon svg path {
        fill: $gray700;
      }
    }

    .risk-flags {
      display: flex;
      height: 100%;
      align-items: center;
      max-width: 55%;

      .icon {
        margin-right: 4px;
        height: 16px;
        width: 16px;
        min-width: 16px;

        svg {
          fill: #FF2E2D;
          path {
            stroke: #fff;
          }
        }
      }

      .risk-flags-label {
        white-space: initial;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        align-self: stretch;
        display: flex;
        align-items: center;
        line-height: 16px;
      }
    }

    .feed-selection-dropdown-wrapper {
      width: 200px;
      height: 32px;
      border-radius: 4px;
      border: 1px solid #F0F0F0;
      cursor: pointer;
      position: relative;

      .selected-feed-value {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: auto 1fr auto;
        column-gap: 4px;
        align-items: center;
        padding: 0 8px;
        background-color: #FFFFFF;
        border-radius: 4px;

        span {
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &.feed-span {
            text-transform: capitalize;
          }

          &.no-feed-span {
            max-width: 100%;
          }
        }

        .icon {
          width: 16px;
          height: 16px;
        }
      }

      .feed-selection-dropdown {
        width: 222px;
        min-height: 32px;
        position: absolute;
        top: 33px;
        left: 0;
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid #F0F0F0;
        box-shadow: 0px 2px 4px 0px #19141414;
        z-index: 999;
        user-select: none;
        padding: 4px 0;

        .feed-selection-dropdown-message {
          display: flex;
          align-items: center;
          font-family: 'Rubik', sans-serif;
          font-size: 10px;
          font-weight: 400;
          line-height: 11.85px;
          background-color: #FAFAFA;
          border-bottom: 1px solid #F0F0F0;
          color: #6D6D6D;
          padding: 0 8px;
          height: 40px;
          cursor: default;
          margin: -4px 0 4px;
        }

        .feed-selection-dropdown-option {
          width: 100%;
          height: 44px;
          cursor: pointer;
          padding: 0 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-transform: capitalize;

          &:hover {
            background-color: rgba(0,0,0, 0.03);
          }

          .radio-input, .checkbox {
            margin-right: 4px;
          }

          span {
            max-width: 90%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .feed-selection-dropdown-option-item {
          display: flex;
          align-items: center;
          gap: 4px;

          .radio-input {
            margin-right: 0;
          }
        }
      }
    }
    .audit-log-dropdown-item {
      .icon svg path {
        stroke: $black;
      }
    }
  }
}

.trading-ui-header__navigator {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  column-gap: 8px;
  row-gap: 4px;
  min-width: fit-content;

  .trading-ui-header__navigator-return {
    grid-row: 1 / 2;
  }

  .trading-ui-header__navigator-search {
  }

  .trading-ui-header__navigator-title {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color .3s;
    padding: 0 4px;

    &:hover {
      background-color: $gray400;
    }

    .icon {
      stroke: #000;
    }

    &.trading-ui-header__navigator-title--active {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  .trading-ui-header__navigator-info {
    display: flex;
    gap: 8px;
    grid-column: 2;
    color: #6D6D6D;
    padding: 0 4px;
  }

  .trading-ui-header__navigator-breadcrumbs {
    display: flex;
    align-items: center;
    gap: 4px;

    .icon {
      stroke: #6D6D6D;
    }
  }

  .trading-ui-header__navigator-breadcrumbs-item {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .trading-ui-header__navigator-separator {
    background: #F0F0F0;
    width: 1px;
  }

  .trading-ui-header__navigator-text {
    display: flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
  }

  .trading-ui-header__navigator-icon {
    height: 100%;
    width: auto;
    fill: #000;
  }
}

.all-markets-display-label {
  display: block;
  margin-left: 4px;
}

.primary-feed-change-warning {
  background-color: transparentize($color: #191414, $amount: 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  &__modal {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    width: 360px;
  }

  &__header {
    display: flex;
    align-items: center;
    height: 56px;
    padding: 0 16px;
    font-family: 'Poppins', sans-serif;
    font-size: 15.72px;
    font-weight: 600;
    line-height: 23.58px;
    color: #191414;
    border-bottom: 1px solid #F0F0F0;
  }

  &__content {
    padding: 16px;
    font-family: 'Rubik', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    color: #000000;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 64px;
    padding: 0 16px;
    border-top: 1px solid #F0F0F0;
  }
}
</style>
