<template>
  <UnsavedChangesPopup
    v-if="isConfirmResetSlidersPopupActive"
    :title="'Reset all sliders'"
    :message="'Reset all sliders back to 0? You can not undo this action.'"
    @onLeaveCancel="() => { isConfirmResetSlidersPopupActive = false; }"
    @onLeaveConfirm="onResetAllSliders"
  />
  <div :class="['trading-alternate-markets-wrapper', { 'multi-view': multiView, 'is-live-double': !isPrematch && doubleSpace }]">
    <div class="trading-alternate-markets-header">
      <div class="trading-alternate-markets-header-left-side">
        <Heading
          v-if="!multiView"
          as="h3"
          size="xs"
        >
          Markets
        </Heading>
        <div class="limited-offer-toggle">
          <Spinner
            v-if="isSetLimitedOfferLoading"
            small
          />
          <Toggle
            v-else
            :model-value="isLimitedOffer"
            @update:model-value="toggleLimitedOffer"
          />
          <div class="limited-offer-label full-label">
            Limited offer
          </div>
          <div class="limited-offer-label short-label">
            <Tooltip
              text="Limited offer"
              right
            >
              LO
            </Tooltip>
          </div>
        </div>
      </div>

      <div class="trading-alternate-markets-header-filters-row">
        <Button
          @click="() => { isConfirmResetSlidersPopupActive = true; }"
          :loading="resetAllSlidersLoading"
          :disabled="resetAllSlidersLoading"
          :variant="'secondary'"
          :icon="'refresh-ccw-1'"
        >
          Reset all sliders
        </Button>
        <div class="trading-alternate-markets-filters__dropdown">
          <div
            class="trading-alternate-markets-filters__dropdown-filter-selected"
            ref="marketFiltersRef"
            @click="() => { openedFiltersDropdown = !openedFiltersDropdown; }"
          >
            <div :class="['trading-alternate-markets-filters__dropdown-filter-selected-label', {'active': selectedMarketFilters.length}]">
              {{ selectedMarketFilters.length ? selectedMarketFilters[0].label : 'State' }}
            </div>
            <div class="trading-alternate-markets-filters__dropdown-filter-selected-icons-group">
              <div
                v-if="selectedMarketFilters.length > 1"
                class="trading-alternate-markets-filters__dropdown-filter-selected-number"
              >
                +{{ selectedMarketFilters.length - 1 }}
              </div>
              <div :class="['trading-alternate-markets-filters__dropdown-filter-selected-icon', {'active': selectedMarketFilters.length}]">
                <Icon
                  :name="'chevron-down'"
                  v-if="!openedFiltersDropdown"
                />
                <Icon
                  :name="'chevron-up'"
                  v-else
                />
              </div>
            </div>
            <div
              class="trading-alternate-markets-filters__dropdown-filter-options"
              v-if="openedFiltersDropdown"
            >
              <div
                class="trading-alternate-markets-filters__dropdown-filter-option"
                v-for="option in marketFilters"
                :key="option.id"
                @click.stop="updateMarketFilter(option)"
              >
                <Checkbox
                  :model-value="filterSelected(option.id)"
                  @update:modelValue="updateMarketFilter(option)"
                />
                {{ option.label }}
              </div>
              <div
                class="trading-alternate-markets-filters__dropdown-filter-option-select"
                @click.stop="selectAllMarketFilters"
              >
                {{ selectedMarketFilters.length ? 'Unselect all' : 'Select all' }}
              </div>
            </div>
          </div>
        </div>
        <div class="trading-alternate-markets-filters__search">
          <TextInput
            :model-value="searchMarketsValue"
            placeholder="Search"
            icon="search"
            @update:modelValue="onSearch"
          />
        </div>
      </div>
    </div>
    <div
      class="trading-alternate-markets-filters"
    >
      <div class="trading-alternate-markets-filters-row">
        <div class="trading-alternate-markets-filters-market-groups">
          <div
            :class="[
              'trading-alternate-markets-filters-market-groups-item',
              { 'trading-alternate-markets-filters-market-groups-item--is-active': !selectedMarketGroupFilter?.code },
            ]"
            @click="updateMarketGroupFilter(null)"
          >
            All
          </div>
          <div
            v-for="group in marketGroups"
            :key="group.code"
            :class="[
              'trading-alternate-markets-filters-market-groups-item',
              { 'trading-alternate-markets-filters-market-groups-item--is-active': selectedMarketGroupFilter?.code === group.code },
            ]"
            @click="updateMarketGroupFilter(group)"
          >
            {{ group.displayName }}
          </div>
          <div
            :class="[
              'trading-alternate-markets-filters-market-groups-item',
              { 'trading-alternate-markets-filters-market-groups-item--is-active': selectedMarketGroupFilter?.code === 'FAVORITES' },
            ]"
            @click="updateMarketGroupFilter('favorites')"
          >
            Favorites
          </div>
        </div>
      </div>
    </div>
    <div class="trading-alternate-markets-batch-control">
      <div :class="['trading-alternate-markets-batch-control-icons', {'disabled': !marketsSelected}]">
        <Checkbox
          :model-value="marketsSelected"
          @update:modelValue="updateSelectedMarkets"
        />
        <Icon
          name="eye"
          @click="batchUpdateMarkets('display')"
        />
        <Icon
          name="eye-off"
          @click="batchUpdateMarkets('undisplay')"
        />
        <Icon
          name="play-circle"
          @click="batchUpdateMarkets('unsuspend')"
        />
        <Icon
          name="pause-circle"
          @click="batchUpdateMarkets('suspend')"
        />
      </div>
      <div class="trading-alternate-markets-batch-control-number">
        {{ selectedTradingMarkets.length }} {{ selectedTradingMarkets.length === 1 ? 'market' : 'markets' }} selected
      </div>
    </div>
    <div :class="['trading-alternate-markets', { 'double-space': doubleSpace, 'is-prematch': isPrematch, 'loading': loading }]">
      <div
        class="market-code-section"
        v-for="marketId in displayMarkets"
        :key="marketId"
      >
        <MarketCodeSection
          :market-id="marketId"
          :selected-trading-markets="selectedTradingMarkets"
          :event-id="eventId"
          :is-usa-view="isUsaView"
          :sport-id="sportId"
          :sport-label="sportLabel"
          :competition-id="competitionId"
          :home-team="homeTeam"
          :away-team="awayTeam"
          :market-favorites="marketFavorites"
        />
      </div>
      <div
        v-if="loading"
        class="trading-alternate-markets-loader"
      >
        <Spinner />
      </div>
    </div>
    <div
      v-if="!loading && !filteredMarkets.length"
      class="trading-alternate-markets-empty-message"
    >
      No markets.
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import {
  includes, filter, find, isNil,
  cloneDeep, map, flatMap, toLower,
} from 'lodash';
import MarketMapper from '@/services/helpers/market-mapper';
import Heading from '@/components/common/Heading';
import Checkbox from '@/components/common/Checkbox';
import Icon from '@/components/common/Icon';
import TextInput from '@/components/common/TextInput';
import Spinner from '@/components/common/Spinner';
import MarketCodeSection from './MarketCodeSection';
import Button from '@/components/common/Button';
import * as api from '@/services/api';
import UnsavedChangesPopup from '@/components/templates/sport-template/common/UnsavedChangesPopup';
import Toggle from '@/components/common/Toggle';
import Tooltip from '@/components/common/Tooltip';

export default {
  components: {
    Heading,
    Checkbox,
    Icon,
    MarketCodeSection,
    TextInput,
    Spinner,
    Button,
    UnsavedChangesPopup,
    Toggle,
    Tooltip,
  },
  props: {
    markets: {
      type: Array,
      required: true,
    },
    isPrematch: {
      type: Boolean,
      required: true,
    },
    doubleSpace: {
      type: Boolean,
      required: true,
    },
    eventId: {
      type: String,
      required: true,
    },
    isUsaView: {
      type: Boolean,
      required: true,
    },
    sportId: {
      type: String,
      required: true,
    },
    sportLabel: {
      type: String,
      required: true,
    },
    competitionId: {
      type: String,
      required: true,
    },
    homeTeam: {
      type: Object,
      required: true,
    },
    awayTeam: {
      type: Object,
      required: true,
    },
    marketsDisplayConfiguration: {
      type: Array,
      required: true,
    },
    multiView: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const searchMarketsValue = ref('');

    const selectedMarketFilters = ref([
      {
        id: 3,
        label: 'Displayed',
        type: 'displayStatus',
        statusValue: 'ON',
      },
    ]);
    const selectedMarketGroupFilter = ref(null);

    const loading = computed(() => store.getters.tradingEventLoading);

    const onSearch = (value) => {
      searchMarketsValue.value = value;
    };

    const selectedTradingMarkets = computed(() => store.getters.selectedTradingMarkets);
    const marketsSelected = computed(() => !!store.getters.selectedTradingMarkets.length);
    const marketFavorites = computed(() => store.getters.marketFavorites);

    const marketFilters = ref([
      {
        id: 1,
        label: 'Active',
        type: 'offeringStatus',
        statusValue: 'ACTIVE',
      },
      {
        id: 2,
        label: 'Suspended',
        type: 'offeringStatus',
        statusValue: 'SUSPENDED',
      },
      {
        id: 3,
        label: 'Displayed',
        type: 'displayStatus',
        statusValue: 'ON',
      },
      {
        id: 4,
        label: 'Hidden',
        type: 'displayStatus',
        statusValue: 'OFF',
      },
      {
        id: 5,
        label: 'Resulted',
        type: 'resultingStatus',
        statusValue: 'RESULTED',
      },
    ]);

    const updateMarketFilter = (filterOption) => {
      if (find(selectedMarketFilters.value, (selectedFilter) => selectedFilter.id === filterOption.id)) {
        selectedMarketFilters.value = filter(selectedMarketFilters.value, (selectedFilter) => selectedFilter.id !== filterOption.id);
        return;
      }
      selectedMarketFilters.value.push(filterOption);
    };

    const batchUpdateMarkets = (action) => {
      store.dispatch('batchUpdateMarkets', { eventId: props.eventId, action, markets: selectedTradingMarkets.value });
    };

    const openedFiltersDropdown = ref(false);
    const marketFiltersRef = ref(false);

    const filterSelected = (filterId) => !!find(selectedMarketFilters.value, (selectedFilter) => selectedFilter.id === filterId);

    const selectAllMarketFilters = () => {
      if (!selectedMarketFilters.value.length) {
        selectedMarketFilters.value = cloneDeep(marketFilters.value);
        return;
      }
      selectedMarketFilters.value = [];
    };

    const openedMarketGroupsDropdown = ref(false);
    const marketGroupsRef = ref(false);
    const marketGroups = computed(() => {
      let marketGroupsBySport = store.getters.marketGroupsBySportId(props.sportId);
      // NCAA events don't have periods so we remove them from the market group list
      if (props.competitionId === 'd1303850-9f46-4ef3-bc0d-11e0b8477d69') {
        marketGroupsBySport = filter(marketGroupsBySport, (marketGroup) => marketGroup.code !== 'PERIOD-BASKETBALL');
      }
      return filter(
        MarketMapper.formatMarketGroups(marketGroupsBySport, props.markets, props.sportLabel),
        (market) => market.matchingMarkets || market.code === 'ALL',
      );
    });
    const updateMarketGroupFilter = (filterOption) => {
      if (filterOption === 'favorites') {
        selectedMarketGroupFilter.value = { code: 'FAVORITES' };
        return;
      }
      selectedMarketGroupFilter.value = filterOption;
    };

    const marketCodes = computed(() => {
      if (selectedMarketGroupFilter.value?.code === 'FAVORITES') {
        return map(marketFavorites.value, (favorite) => favorite.code);
      }
      const groupFilters = (selectedMarketGroupFilter.value ? [selectedMarketGroupFilter.value] : [marketGroups.value]);
      const groupValues = map(groupFilters, ({ code }) => code);
      const allMarketCodes = flatMap(groupValues, (groupValue) => {
        const foundGroup = find(marketGroups.value, (marketGroup) => includes(marketGroup.code, groupValue));
        return foundGroup?.marketCodes?.nodes || [];
      });
      return map(allMarketCodes, ({ marketCode }) => marketCode);
    });

    const filteredMarkets = computed(() => {
      const displayStatusFilters = filter(selectedMarketFilters.value, (filterItem) => filterItem.type === 'displayStatus');
      const offeringStatusFilters = filter(selectedMarketFilters.value, (filterItem) => filterItem.type === 'offeringStatus');
      const resultingStatusFilters = filter(selectedMarketFilters.value, (filterItem) => filterItem.type === 'resultingStatus');
      const displayStatus = displayStatusFilters.length === 1 ? displayStatusFilters[0].statusValue : false;
      const offeringStatus = offeringStatusFilters.length === 1 ? offeringStatusFilters[0].statusValue : false;
      const resultingStatus = resultingStatusFilters.length ? resultingStatusFilters[0].statusValue : false;

      const marketsMatchingStatuses = filter(props.markets, (market) => (!displayStatus && !offeringStatus && !resultingStatus)
        || ((displayStatus && market.displayStatus === displayStatus)
        || (offeringStatus && market.offeringStatus === offeringStatus)
        || (resultingStatus && market.resultingStatus === resultingStatus)));
      return filter(marketsMatchingStatuses,
        (market) => (includes(toLower(market.marketCode), searchMarketsValue.value)
          || includes(toLower(market.marketSummary?.playerName), searchMarketsValue.value)
          || (!isNil(market.marketType.params.TEAM) && includes(toLower(props.homeTeam?.name), searchMarketsValue.value))
          || (!isNil(market.marketType.params.TEAM) && includes(toLower(props.awayTeam?.name), searchMarketsValue.value))
          || includes(toLower(find(props.marketsDisplayConfiguration,
            (displayMarket) => displayMarket.marketCode === market.marketCode
              && displayMarket.selections === market.marketType.params.SELECTIONS)?.defaultName), searchMarketsValue.value))
          && (!selectedMarketGroupFilter.value || (selectedMarketGroupFilter.value && includes(marketCodes.value, market.marketCode))));
    });

    const marketDisplayConfigurations = computed(() => filter(map(props.marketsDisplayConfiguration, (marketConfiguration) => ({
      ...marketConfiguration,
      marketIds: map(MarketMapper.sortSectionMarkets(filter(filteredMarkets.value, (market) => market.marketCode === marketConfiguration.marketCode
      && market.marketType.params.SELECTIONS === marketConfiguration.selections), props.isUsaView), (filterMarket) => filterMarket.marketId),
    })), (marketDisplayConfiguration) => marketDisplayConfiguration.marketIds?.length));

    const displayMarkets = computed(() => flatMap(marketDisplayConfigurations.value, (marketConfiguration) => marketConfiguration.marketIds));

    const updateSelectedMarkets = () => {
      if (marketsSelected.value) {
        store.dispatch('updateAllSelectedTradingMarkets', { action: 'clear' });
      } else {
        store.dispatch('updateAllSelectedTradingMarkets', {
          action: 'selectAll',
          markets: filter(filteredMarkets.value,
            (market) => market.resultingStatus !== 'RESULTED' && find(market.selections, (selection) => selection.price?.originalFormattedValue?.value)),
        });
      }
    };

    onClickOutside(marketFiltersRef, () => { openedFiltersDropdown.value = false; });
    onClickOutside(marketGroupsRef, () => { openedMarketGroupsDropdown.value = false; });

    const isConfirmResetSlidersPopupActive = ref(false);
    const resetAllSlidersLoading = ref(false);
    const onResetAllSliders = () => {
      resetAllSlidersLoading.value = true;
      isConfirmResetSlidersPopupActive.value = false;
      api.resetMarketBias({ eventId: props.eventId }).finally(() => {
        resetAllSlidersLoading.value = false;
      });
    };

    const tradingEventParams = computed(() => store.getters.tradingEvent?.params);
    const multiviewListEvent = computed(() => store.getters.getSingleMultiviewListEvent(props.eventId));
    const multiviewEventParams = computed(() => multiviewListEvent.value?.params);
    const isLimitedOffer = computed(() => (props.multiView ? multiviewEventParams.value?.selectedMarkets?.limitedOffer : tradingEventParams.value?.selectedMarkets?.limitedOffer));
    const isSetLimitedOfferLoading = ref(false);
    const toggleLimitedOffer = () => {
      isSetLimitedOfferLoading.value = true;
      store.dispatch('setLimitedOffer', {
        eventId: props.eventId,
        sportId: props.sportId,
        isLimitedOffer: !isLimitedOffer.value,
      }).finally(() => {
        isSetLimitedOfferLoading.value = false;
      });
    };

    watch(marketGroups, (newValue, oldValue) => {
      if (!oldValue?.length && newValue?.length) {
        const mainMarketGroup = find(marketGroups?.value, (group) => group.displayName === 'Main');
        if (mainMarketGroup) selectedMarketGroupFilter.value = mainMarketGroup;
      }
    });

    return {
      loading,
      searchMarketsValue,
      onSearch,
      filteredMarkets,
      selectedTradingMarkets,
      marketsSelected,
      updateSelectedMarkets,
      batchUpdateMarkets,
      marketFilters,
      selectedMarketFilters,
      updateMarketFilter,
      marketFiltersRef,
      openedFiltersDropdown,
      filterSelected,
      selectAllMarketFilters,
      marketGroupsRef,
      openedMarketGroupsDropdown,
      selectedMarketGroupFilter,
      updateMarketGroupFilter,
      marketGroups,
      marketCodes,
      displayMarkets,
      marketDisplayConfigurations,
      marketFavorites,
      resetAllSlidersLoading,
      onResetAllSliders,
      isConfirmResetSlidersPopupActive,
      isLimitedOffer,
      toggleLimitedOffer,
      isSetLimitedOfferLoading,
    };
  },
};
</script>

<style lang="scss">
.trading-alternate-markets-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #DDD;
  background-color: #FFF;
  height: 100%;

  &.multi-view {
    border: 0;
  }

  &.is-live-double {
    height: calc(100vh - 382px);
  }

  .trading-alternate-markets-header {
    width: 100%;
    height: 64px;
    min-height: 64px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    padding: 0 16px;
    justify-content: space-between;

    .trading-alternate-markets-header-left-side {
      display: flex;
      align-items: center;
      gap: 8px;

      .limited-offer-toggle {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 8px;
        .limited-offer-label {
          font-size: 14px;
          font-weight: 400;
          &.full-label {
            display: block;
          }
          &.short-label {
            display: none;
          }
        }
      }
    }

    .trading-alternate-markets-header-filters-row {
      display: flex;
      justify-content: flex-end;
      width: 45%;
      flex-direction: row;
      font-size: 14px;
      font-weight: 400;
      height: 100%;
      align-items: center;
      padding: 0;
    }
  }
  &.multi-view {
    .trading-alternate-markets-header {
      .trading-alternate-markets-header-left-side {
        .limited-offer-toggle {
          .limited-offer-label {
            &.full-label {
              display: none;
            }
            &.short-label {
              display: block;
            }
          }
        }
      }
    }
  }

  .trading-alternate-markets-filters-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
  }

  .trading-alternate-markets-filters-market-groups {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    user-select: none;
    overflow-x: auto;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    position: relative;
    width: 100%;

    &::before {
      background: #F0F0F0;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      content: "";
    }

    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }

    .trading-alternate-markets-filters-market-groups-item {
      display: flex;
      height: var(--Height-md, 32px);
      padding: 0px var(--padding-xsmall, 8px);
      align-items: center;
      gap: var(--Spacing-sm, 4px);
      border-bottom: 2px solid var(--neutral-stroke-default-resting, #F0F0F0);
      background: var(--neutral-bg-default-resting, #FFF);
      white-space: nowrap;
      cursor: pointer;
      position: relative;

      &--is-active {
        border-bottom: 2px solid var(--primary-stroke-weak-active, #003C3C);
      }
    }
  }

  .trading-alternate-markets-filters, .trading-alternate-markets-header-filters-row {
    width: 100%;
    padding: 8px 16px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 8px;

    .trading-alternate-markets-filters__dropdown {
      height: 33px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      position: relative;
      z-index: $dropdownMenuZIndex;
      flex: 1;

      .trading-alternate-markets-filters__dropdown-label {
        height: 12px;
        font-size: 10px;
        font-weight: 600;
        color: #A9A9A9;
      }

      .trading-alternate-markets-filters__dropdown-filter-selected {
        height: 33px;
        width: 100%;
        display: flex;
        background-color: #fff;
        padding: 8px 12px;
        border: 1px solid #F0F0F0;
        border-radius: 4px;
        align-items: center;
        justify-content: space-between;
        position: relative;
        cursor: pointer;

        .trading-alternate-markets-filters__dropdown-filter-selected-label {
          color: #A9A9A9;

          &.active {
            color: #191414;
          }
        }

        .trading-alternate-markets-filters__dropdown-filter-selected-icons-group {
          display: flex;
          height: 100%;
          align-items: center;

          .trading-alternate-markets-filters__dropdown-filter-selected-number {
            display: flex;
            padding: 2px 4px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            font-weight: 400;
            color: #fff;
            border-radius: 24px;
            background: #003C3C;
          }

          .trading-alternate-markets-filters__dropdown-filter-selected-icon {
            height: 24px;
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-left: 8px;

            .icon {
              height: 16px;
              width: 16px;

              svg {

                path {
                  stroke: #A9A9A9;
                  stroke-width: 1;
                }
              }
            }

            &.active {
              .icon {
                svg {
                  path {
                    stroke: #191414;
                  }
                }
              }
            }
          }
        }
        .trading-alternate-markets-filters__dropdown-filter-options {
          width: 172px;
          min-height: 30px;
          position: absolute;
          top: 37px;
          left: 0;
          border-radius: 4px;
          border: 1px solid #DDD;
          background: #FFF;
          box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.12);

          .trading-alternate-markets-filters__dropdown-filter-info {
            height: 33px;
            width: 100%;
            display: flex;
            align-items: center;
            padding: 0 8px;
            opacity: 0.4;
            cursor: default;
          }

          .trading-alternate-markets-filters__dropdown-filter-option {
            height: 33px;
            width: 100%;
            display: flex;
            align-items: center;
            padding: 0 8px;
            cursor: pointer;

            &:not(.trading-alternate-markets-filters__dropdown-filter-option--market-groups) {
              &:nth-child(2), &:nth-child(4) {
                border-bottom: 1px solid #F0F0F0;
              }
            }

            &:hover {
              background-color: #FAFAFA;;
            }

            .checkbox {
              margin-right: 8px;
            }
          }

          .trading-alternate-markets-filters__dropdown-filter-option-select {
            width: 100%;
            height: 33px;
            border-top: 1px solid #F0F0F0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
              background-color: #FAFAFA;;
            }
          }
        }
      }
    }

    .trading-alternate-markets-filters__search {
      height: 33px;
      flex: 1;
      min-width: 150px;

      .text-field {
        height: 33px;

        .text-field-clear {
          width: 33px;
          height: 33px;
        }
      }
    }
  }

  .trading-alternate-markets-batch-control {
    height: 48px;
    min-height: 48px;
    width: 100%;
    padding:  0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FAFAFA;
    border-top: 1px solid #F0F0F0;
    border-bottom: 1px solid #F0F0F0;

    .trading-alternate-markets-batch-control-icons {
      height: 100%;
      display: flex;
      align-items: center;

      .checkbox {
        margin-right: 8px;
        cursor: pointer;
      }

      .icon {
        height: 24px;
        width: 24px;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
          height: 16px;
          width: 16px;
          path {
            stroke: #191414;
            stroke-width: 0;
          }
        }

        &.checkbox-icon {
          height: 12px;
          width: 12px;

          svg {
            path {
              stroke: #fff;
              stroke-width: 1;
            }
          }
        }
      }

      &.disabled {
        .icon {
          cursor: default;
          svg {
            path {
              stroke: #A9A9A9;
              fill: #A9A9A9;
            }
          }
        }
      }
    }

    .trading-alternate-markets-batch-control-number {
      color: #A9A9A9;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .trading-alternate-markets {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    overflow-y: auto;

    .market-code-section {
      width: 100%;
    }

    &.loading {
      height: 100%;
    }

    .trading-market, .market-code-section {
      .trading-market-selections-wrapper {
        .trading-market-selections {
          .trading-market-selection {
            .trading-market-selection-name {
              width: 65%;
            }
            .trading-market-selection-values-group {
              width: 35%;
            }
          }
        }
      }
    }

    &.double-space {
      .trading-market, .market-code-section {
        flex: 50%;

        &:nth-child(odd) {
          border-right: 1px solid #DDD;
        }

        &:last-child {
          border-right: 0;
        }

        &.suspended {
          &:nth-child(odd) {
            border-right: 1px solid #FFD5D5;
          }
        }
      }

      &.is-prematch {
      }
    }
  }

  .trading-alternate-markets-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .trading-alternate-markets-empty-message {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #A9A9A9;
    text-align: center;
  }

  &.multi-view {
    .trading-alternate-markets {
      max-height: calc(100vh - 418px);

      .market-code-section {
        &:last-child {
          .trading-market {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

@media(max-width: 1400px) {
  .trading-alternate-markets-wrapper {
    .trading-alternate-markets-header {
      .trading-alternate-markets-header-left-side {
        .limited-offer-toggle {
          .limited-offer-label {
            &.full-label {
              display: none;
            }
            &.short-label {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
